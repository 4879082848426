<template>
  <div class="full_popup" v-if="details.debitOrderID">
    <div class="row h-100 m-0 align-items-center justify-content-center">
      <div class="col-11 col-md-8 col-lg-6 col-xxl-6 p-0 card_item">
        <div class="row mx-0 h-100 justify-content-center">
          <div class="col-12 p-3 pb-0">
            <div class="row m-0 justify-content-center holder">
              <div class="bg_holder">
                <div class="background" >
                </div>
              </div>
              <div class="col-12 p-3 above">
                <div class="row mb-5 d-md-none">
                  <div class="col-12 text-center">
                    <Button color="dark" size="small" class="white-text" btnText="Back to Debit Orders" icon2="arrow" @buttonClicked="$emit('close')">
                      <template v-slot:iconStart>
                        <IconArrowBack size="size16" />
                      </template>
                    </Button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-10 offset-md-1 pt-2">
                    <div class="row justify-content-center">
                      <div class="col-auto text-center p-0 card_title">
                        UPDATE BANK DETAILS
                        <div class="row m-0 justify-content-center" >
                          <div class="col-12 text-center pt-3 border_bottom">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-end cursor d-none d-md-block" @click="$emit('close')">
                    <ios-close-icon class="inline-icon green-text-dark" />
                  </div>
                </div>
                <div class="row m-0 justify-content-center">
                  <div class="col-12">
                    <div class="row m-0 justify-content-center" >
                      <div class="col-md-11 px-0 my-3 card_item_inner">
                        <div class="row mx-0 h-100 justify-content-center">
                          <div class="col-12 p-0">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="row mx-0">
                                  <div class="col-12 p-0 mb-1 medium font09">
                                    Bank:
                                  </div>
                                  <div class="col-12 p-0 mb-3">
                                    <SelectAll
                                      v-model="selectedBank"
                                      id="debitMethod"
                                      :options="bankOptions"
                                      placeholder="Bank"
                                      displayValue="bankName"
                                      color="gold"
                                      :hasMore="true"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <TextField type="text" color="gold" placeholder="Name" label="Account Holder Name:" v-model="details.donorBankAccount.accountHolderName" id="name" />
                              </div>
                              <div class="col-6">
                                <TextField type="text" color="gold" placeholder="Account Number" label="Account Number:" v-model="details.donorBankAccount.accountNumber" id="number" />
                              </div>
                              <div class="col-6">
                                <TextField type="text" color="gold" placeholder="Branch code" label="Branch Code:" v-model="details.donorBankAccount.branchCode" id="branch" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--BUTTONS-->
          <div class="col-12 px-4 align-self-end background-green">
            <div class="row py-3 align-items-center justify-content-center">
              <div class="col-8 col-md-5 px-2 mb-2 mb-md-0" >
                <Button color="red" size="" width="100" btnText="Cancel Changes" icon="arrow" @buttonClicked="cancelEdit">
                  <ios-close-circle-outline-icon class="inline-icon" />
                </Button>
              </div>
              <div class="col-8 col-md-5 px-2" >
                <Button color="green_light" size="" width="100" btnText="Update Bank Details" icon="arrow" @buttonClicked="save" :isLoading="isLoading">
                  <IconArrowForward size="size16"/>
                </Button>
              </div>
            </div>
          </div>
          <!--END BUTTONS-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    SelectAll: defineAsyncComponent(() => import('../components/SelectAll.vue')),
    TextField: defineAsyncComponent(() => import('../components/TextField.vue')),
    IconArrowForward: defineAsyncComponent(() => import('../components/icons/IconArrowForward.vue')),
    IconArrowBack: defineAsyncComponent(() => import('../components/icons/IconArrowBack.vue')),
    'ios-close-circle-outline-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close-circle-outline.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'DebitOrderEditBankPopup',
  props: ['item'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      details: {},
      selectedBank: null,
      isLoading: false,
      checkCurrent: false
    }
  },
  async mounted () {
    this.checkCurrent = false
    this.details = { ...this.item }
    await this.fetchBankOptions()
    await this.currentBank()
  },
  computed: {
    ...mapGetters([
      'cart', 'user', 'bankOptions'
    ])
  },
  watch: {
    selectedBank () {
      if (Object.keys(this.selectedBank).length > 0 && this.checkCurrent) {
        this.details.donorBankAccount.bankName = this.selectedBank.bankName
        if (this.details.donorBankAccount.bankBranch) {
          this.details.donorBankAccount.bankBranch.bankBranchID = this.selectedBank.bankID
        }
      }
    },
    details: {
      deep: true,
      handler (val) {
        this.setBranchCode()
      }
    }
  },
  methods: {
    ...mapActions(['updateDebitOrder', 'fetchBankOptions']),
    async save () {
      this.isLoading = true
      await this.updateDebitOrder(this.details)
      this.isLoading = false
      await this.cancelEdit()
    },
    setBranchCode () {
      if (this.details.donorBankAccount.bankBranch) {
        this.details.donorBankAccount.bankBranch.code = this.details.donorBankAccount.branchCode
      }
    },
    cancelEdit () {
      this.$emit('close')
    },
    currentBank () {
      if (this.bankOptions && this.bankOptions.length > 0) {
        var theBank = this.bankOptions.find(bank => this.details.donorBankAccount.bankName === bank.bankName)
        this.selectedBank = theBank
        this.checkCurrent = true
      }
    }
  }
}
</script>
<style scoped>
.full_popup {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
}
.card_item {
  background-color: #F4F4F4F7;
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.card_item_inner {
  background-color: rgb(249, 250, 251);
  border-radius: 15px;
  /* box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%); */
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  z-index: 1;
  background-image: url(../assets/images/bg.png);
  background-size: 32% auto;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: linear-gradient(180deg, #fffffff7 0%, #ffffff 100%);
  position: absolute;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-position: top center;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--green-color-dark);
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.profile_img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
}
.item {
  color: #4E5356;
  font-size: 15px;
}
.delete {
  color: #E0E0E0;
  cursor: pointer;
}
.delete:hover {
  color: var(--red-color);
}
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}
.hide {
  visibility: hidden;
}
.background-grey {
  background-color: rgb(244, 244, 244);
}
.item_nav {
  background-color: rgba(0, 0, 0, 0.53);
}
.hover:hover {
  color: var(--gold-color);
}
.disabled {
  opacity: 0.7;
}
.card_holder {
  border-radius: 15px;
}
.title {
  color: #3A3A3A;
  font-family: "quicksand_bold", Sans-serif;
  font-size: 17px;
  font-weight: 600;
}
.bg {
  background-color: #F4F4F4;
}
.bg:hover {
  background-color: #ffffff;
}
.bg_green {
  background-color: var(--green-color-dark);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.above_z {
  position: relative;
  z-index: 2;
}

.full_popup {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
}
.card_item {
  background-color: #F4F4F4F7;
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.card_item_inner {
  background-color: rgb(249, 250, 251);
  border-radius: 15px;
  /* box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%); */
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  z-index: 1;
  background-image: url(../assets/images/bg.png);
  background-size: 32% auto;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: linear-gradient(180deg, #fffffff7 0%, #ffffff 100%);
  position: absolute;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-position: top center;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--green-color-dark);
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.profile_img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
}
.item {
  color: #4E5356;
  font-size: 15px;
}
.delete {
  color: #E0E0E0;
  cursor: pointer;
}
.delete:hover {
  color: var(--red-color);
}
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}
.hide {
  visibility: hidden;
}
.background-grey {
  background-color: rgb(244, 244, 244);
}
.item_nav {
  background-color: rgba(0, 0, 0, 0.53);
}
.hover:hover {
  color: var(--gold-color);
}
.disabled {
  opacity: 0.7;
}
.selectFrequency .form-control {
    background-color: var(--green-color-dark);
    color: var(--gold-color);
    border-radius: 25px;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0;
    font-weight: 500;
    text-align: center;
  }
  .selectFrequency .form-control:focus {
    background-color: transparent;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
  }
  .selectFrequency .form-control-inner {
    background-color: transparent;
    position: relative;
    color: var(--gold-color);
    border-radius: 25px;
    border: none;
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0 1rem;
    font-weight: 500;
    text-align: center;
    z-index: 2;
  }

  .selectFrequency .holder {
    position: relative;
  }
  .selectFrequency .dropdown_icon {
    position: absolute;
    color: var(--gold-color);
    top: 0.8rem;
    right: 0.7rem;
    z-index: 1;
  }
</style>
